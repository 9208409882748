import { uuid } from "~/lib/uuid/client";
import slugify from "slugify";

export function slugifyUsername(username: string): string {
  return slugify(username, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
    locale: "en", // language code of the locale to use
    trim: true, // trim leading and trailing replacement chars, defaults to `true`
  });
}

export function generateSlugifiedUsername(
  firstName: string,
  lastName: string
): string {
  const uniqueChars = uuid().substring(0, 2);
  const preSlug = `${firstName}-${lastName}-${uniqueChars}`;

  return slugifyUsername(preSlug);
}

export function isValidUsernameSlug(username: string): boolean {
  return username === slugifyUsername(username);
}
