import Link from "next/link";
import { PageMeta } from "~/components/utils/PageMeta";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { createServerSupabaseClient } from "@supabase/auth-helpers-nextjs";
import UserLogos from "~/components/elements/UserLogos";
import URLPrefixInput from "~/components/forms/general/URLPrefixInput";
import { uuid } from "~/lib/uuid/client";
import Logo from "~/components/layout/Logo";
import { useRouter } from "next/router";
import Script from "next/script";
import { useSupabaseClient } from "~/lib/AuthProvider";
import { PluralizedEntityType } from "~/types";
import { generateSlugifiedUsername } from "~/lib/slug";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ref, setRef] = useState("");

  const supabase = useSupabaseClient();
  const router = useRouter();

  // For hidden field
  useEffect(() => {
    const { ref } = router.query;
    if (typeof ref === "string") {
      setRef(ref);
    }
  }, [router.query.ref]);

  const handleSignup = async (e) => {
    e.preventDefault();
    console.log("Signup attempted:" + email + firstName + lastName);

    if (!email || !firstName || !lastName || !linkedin) {
      console.log("Missing required fields");
      alert("Please fill out all required fields");
      return;
    }

    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();

    // Check if user already has account
    const { data: user, error } = await supabase
      .from(PluralizedEntityType.CompletedUsers)
      .select("id, email, username")
      .eq("email", email);

    if (error) {
      const errorMessage = `Failed in getting ${
        PluralizedEntityType.CompletedUsers
      } by email [${email}] with error: ${JSON.stringify(error)}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    if (user === null || user === undefined) {
      const errorMessage = `Got a ${user} ${PluralizedEntityType.CompletedUsers} by email [${email}]`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    if (user.length !== 0) {
      alert(
        "It looks like you already have an account. Please log in instead."
      );
      return;
    }

    // Clean Linkedin URL
    const regex = /\/in\/([a-zA-Z0-9-]+)/;
    const match = regex.exec(linkedin);

    if (match) {
      const linkedinID = match[1];
      await setLinkedin(linkedinID);
      console.log("Cleaned Linkedin: ", linkedinID);
    }

    try {
      setLoading(true);
      const slugifiedUsername = generateSlugifiedUsername(
        trimmedFirstName,
        trimmedLastName
      );

      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: window.location.origin + "/onboard/portfolio-goals",
          data: {
            first_name: trimmedFirstName,
            last_name: trimmedLastName,
            linkedin,
            initial_username: slugifiedUsername,
            email: email,
            referrer: ref,
          },
        },
      });

      if (error) throw error;
      setSubmitted(true);
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
      window.rudderanalytics.identify(email, {
        name: trimmedFirstName + " " + trimmedLastName,
        linkedin: linkedin,
      });
      window.rudderanalytics.track("signup", {
        $set_once: { userProperty: email },
      });
    }
  };

  const handleWaitlistSignup = async (e) => {
    e.preventDefault();
    console.log("submit attempted:" + email);

    if (!email || !linkedin || !firstName || !lastName) {
      console.log("Missing required fields");
      alert("Please fill out all required fields");
      return;
    }

    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();

    try {
      setLoading(true);
      const { data, error } = await supabase
        .from(PluralizedEntityType.WaitlistUsers)
        .insert([
          {
            email,
            linkedin,
            first_name: trimmedFirstName,
            last_name: trimmedLastName,
          },
        ]);
      if (error) throw error;
      setSubmitted(true);
      // await postWaitlist(email); // Attempt at Slack message but not working
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
      window.rudderanalytics.identify(email, {
        name: trimmedFirstName + " " + trimmedLastName,
        linkedin: linkedin,
      });
      window.rudderanalytics.track("waitlist_signup", {
        $set_once: { userProperty: email },
      });
    }
  };

  const metaTitle = "Create a free portfolio website with AI | Showspace";
  const metaDescription =
    "Beat the FAANG and Ivy League candidates to the offer with a free portfolio website powered by AI. get hired by showing how your experience is more valuable than their brand name";
  const metaImage =
    "https://chmqmeyyaiwfybqgcdoy.supabase.co/storage/v1/object/public/application/showspace-portfolio-social-3-9-23.jpg";
  const metaUrl = "https://showspace.so";

  return (
    <>
      <PageMeta
        description={metaDescription}
        imageUrl={metaImage}
        title={metaTitle}
        url={metaUrl}
      />
      <section>
        <div className="mx-auto mt-8 max-w-screen-xl sm:px-4 lg:mt-12 lg:grid lg:grid-cols-12 lg:gap-20 xl:mb-12 xl:px-0">
          <div className="mr-auto flex-col md:col-span-5 lg:flex xl:col-span-6 xl:mb-0">
            <div>
              <div className="mb-2 mt-6 flex lg:mt-0 lg:block">
                <Logo />
              </div>
              <h1 className="mx-auto mb-12 max-w-2xl text-center text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-left">
                Unlock professional superpowers with a free portfolio website
                powered by AI
              </h1>
              <div className="mb-6 flex-row pb-6 xl:mb-10 xl:pb-10">
                <div className="flex-row justify-center lg:flex lg:justify-start">
                  <div className="mr-5 mb-4 mt-3 text-center text-3xl lg:text-left">
                    💪
                  </div>
                  <div>
                    <h3 className="mb-2 text-center text-xl font-bold leading-none text-gray-900 dark:text-white lg:text-left">
                      Beat ex-FAANG candidates to the offer
                    </h3>
                    <p className="mb-8 text-center font-light text-gray-500 dark:text-gray-400 lg:mb-2 lg:text-left">
                      Show how your accomplishments are more valuable than their
                      brand name
                    </p>
                  </div>
                </div>
                <div className="flex-row justify-center lg:flex lg:justify-start lg:pt-8">
                  <div className="mr-5 mt-3 text-center text-3xl lg:text-left">
                    🤖
                  </div>
                  <div>
                    <h3 className="mb-2 text-center text-xl font-bold leading-none text-gray-900 dark:text-white lg:text-left">
                      Easily build your portfolio with GPT
                    </h3>
                    <p className="mb-8 text-center font-light text-gray-500 dark:text-gray-400 lg:mb-2 lg:text-left">
                      Use AI to craft your projects in minutes in a way proven
                      to win jobs
                    </p>
                  </div>
                </div>
                <div className="flex-row justify-center lg:flex lg:justify-start lg:pt-8">
                  <div className="mr-5 mt-3 text-center text-3xl lg:text-left">
                    📈
                  </div>
                  <div>
                    <h3 className="mb-2 text-center text-xl font-bold leading-none text-gray-900 dark:text-white lg:text-left">
                      Share your work with hiring managers
                    </h3>
                    <p className="mb-8 text-center font-light text-gray-500 dark:text-gray-400 lg:mb-2 lg:text-left">
                      Control who sees your work and track when your portfolio
                      gets viewed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <UserLogos />
          </div>

          <div className="mx-auto mb-16 flex w-full flex-col-reverse items-center pt-12 lg:col-span-7 lg:mb-0 lg:mt-0 lg:flex-col xl:col-span-6">
            <div className="space-y-4 rounded-lg border border-gray-100 bg-white p-6 shadow-lg dark:bg-gray-800 sm:max-w-lg sm:p-8 lg:space-y-6">
              {submitted === true ? (
                <div className="flex h-[466px] w-[436px] flex-col items-center justify-center text-gray-800">
                  <h2>Check your email for the sign in link!</h2>
                </div>
              ) : (
                <>
                  <h2 className="mb-8 text-center text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white sm:text-2xl lg:text-left">
                    Create your Showspace Portfolio
                  </h2>
                  {/* <div className="items-center space-y-3 sm:flex sm:space-x-4 sm:space-y-0">
              <a
                href="#"
                className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white py-2.5 px-5 text-sm  text-gray-900 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
              >
                <svg
                  className="mr-2 h-5 w-5"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_13183_10121)">
                    <path
                      d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z"
                      fill="#3F83F8"
                    />
                    <path
                      d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z"
                      fill="#FBBC04"
                    />
                    <path
                      d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z"
                      fill="#EA4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_13183_10121">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Sign up with Google
              </a>
            </div>
              <div className="flex items-center">
              <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700"></div>
              <div className="px-5 text-center text-gray-500 dark:text-gray-400">
                or
              </div>
              <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700"></div>
            </div> */}

                  <form
                    className="space-y-4 lg:space-y-6"
                    onSubmit={handleSignup}
                  >
                    <div className="flex flex-col sm:flex-row">
                      <div className="flex-1 sm:mr-4">
                        <label
                          htmlFor="first-name"
                          className="mb-2 block text-sm font-semibold text-gray-900 dark:text-white"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-primary-600 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                          required
                          onChange={(e) => setFirstName(e.target.value)}
                          autoComplete="given-name"
                        />
                      </div>
                      <div className="mt-4 flex-1 sm:mt-0">
                        <label
                          htmlFor="last-name"
                          className="mb-2 block text-sm font-semibold text-gray-900 dark:text-white"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-primary-600 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                          required
                          onChange={(e) => setLastName(e.target.value)}
                          autoComplete="family-name"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="mb-2 block text-sm font-semibold text-gray-900 dark:text-white"
                      >
                        Email
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <EnvelopeIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-gray-900 focus:border-primary-600 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          autoComplete="email"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="mb-2 block text-sm font-semibold text-gray-900 dark:text-white"
                      >
                        LinkedIn
                      </label>
                      <URLPrefixInput
                        name="linkedin"
                        id="linkedin"
                        urlPrefix="linkedin.com/in/"
                        onInputChange={setLinkedin}
                      />
                      <input type="hidden" value={ref} />
                      <a
                        className="ml-1 text-sm text-primary-400 hover:underline dark:text-primary-500"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/"
                      >
                        Visit LinkedIn.com
                      </a>
                    </div>
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="terms"
                          aria-describedby="terms"
                          type="checkbox"
                          className="focus:ring-3 h-4 w-4 rounded border border-gray-300 bg-gray-50 focus:ring-primary-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600"
                          required
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="terms"
                          className="font-light text-gray-500 dark:text-gray-300"
                        >
                          {`By signing up you agree to our`}
                          <Link
                            className="ml-0.5  text-primary-600 hover:underline dark:text-primary-500"
                            href="/terms"
                          >
                            Terms of Service
                          </Link>{" "}
                          and{" "}
                          <Link
                            className=" text-primary-600 hover:underline dark:text-primary-500"
                            href="/privacy"
                          >
                            Privacy Policy
                          </Link>
                        </label>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="w-full rounded-lg bg-primary-600 px-5 py-2.5 text-center text-sm font-semibold text-white hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    >
                      Create account
                    </button>

                    <p className="text-center text-sm font-light text-gray-500 dark:text-gray-400">
                      Already have an account?{" "}
                      <Link
                        href="/signin"
                        className=" font-semibold text-primary-600 hover:underline dark:text-primary-500"
                      >
                        Sign in
                      </Link>
                    </p>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export const getServerSideProps = async (ctx) => {
  // Create authenticated Supabase Client
  const supabase = createServerSupabaseClient(ctx);
  // Check if we have a session
  const {
    data: { session },
  } = await supabase.auth.getSession();

  if (session) {
    // query supabase to grab details from public.users table

    const { data: user } = await supabase
      .from(PluralizedEntityType.Users)
      .select("username")
      .eq("id", session.user.id)
      .single();

    return {
      redirect: {
        destination: "/s/" + user.username,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};
