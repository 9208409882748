import HelperText from "~/components/forms/general/HelperText";
import Label from "~/components/forms/general/FormLabel";
import Invalid from "~/components/forms/general/Invalid";
import CornerHint from "~/components/forms/general/CornerHint";
import { useState } from "react";

interface URLPrefixInputProps {
  value?: string;
  onInputChange: (value: string) => void;
  description?: string;
  rows?: number;
  placeholder?: string;
  helperText?: string;
  valid?: boolean;
  invalidMessage?: string;
  hintMessage?: string;
  label?: string;
  urlPrefix: string;
  id: string;
  name: string;
}

export default function URLPrefixInput({
  value,
  onInputChange,
  description,
  rows,
  placeholder,
  helperText,
  valid,
  invalidMessage,
  hintMessage,
  label,
  urlPrefix,
  id,
  name,
}: URLPrefixInputProps) {
  const [input, setInput] = useState("");

  const handleInputChange = (event) => {
    onInputChange(event.target.value);
    setInput(event.target.value);
  };

  return (
    <div className="sm:col-span-4">
      <Label label={label} />
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
          {urlPrefix}
        </span>
        <input
          type="text"
          name={name}
          id={id}
          value={value}
          onChange={handleInputChange}
          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      {invalidMessage ? <Invalid message={invalidMessage} /> : null}
      {helperText ? <HelperText helperText={helperText} /> : null}
    </div>
  );
}
